// 审批申请单配置
const open = function() {
  return [
    {
      modules: [
        {
          template: 1,
          name: '费用申请',
          approvalKind: 'expenses',
          refName: 'expenses',
          icon: 'icon-feiyongshenqing',
          color: '#ff7b2c',
          size: '40px',
          // permi: 'tenant:iot:expenses:add',
          component: () => import('./modules/expenses')
        },
        {
          template: 2,
          name: '充值申请',
          approvalKind: 'recharge',
          refName: 'recharge',
          icon: 'icon-ywzjicon-gas',
          color: '#ff4e00',
          size: '48px',
          // permi: 'tenant:iot:oilAccountRecord:add',
          component: () => import('./modules/oilAccountRecord/rechargeForm')
        },
        {
          template: 3,
          name: '合同审批',
          approvalKind: 'contract',
          refName: 'contract',
          icon: 'icon-ywzjicon-gas ',
          color: '#ff4e00',
          size: '48px',
          // permi: 'tenant:iot:approvalContract:add',
          component: () => import('./modules/contract')
        }
      ]
    }
  ]
}
export const openModules = vm => {
  return open.call(vm)
}
